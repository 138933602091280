.content{
    .dashboard{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        
        .AnalyticSelector{
            display: flex;
            flex-direction: column;
            width: fit-content;
            hr{
                width: 120%;
                color: black;
                background-color: black;
                z-index: 600;
            }

            justify-content: center;
            align-items: center;

            .sections{
                display: flex;

                .section{
                    padding: 30px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    cursor: pointer;
                    color: black;
                    font-size: normal;

                    margin: 5px;
                    margin-top: -2px;
    
                    border: 1px solid gray;
                    background-color: whitesmoke;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
    
                    &:hover{
                        color: #3498DB;
                        background-color: whitesmoke;
                    }
                }

                .section.true{
                    color: white;
                    background-color: #3498DB;
                    
                    margin-bottom: 2px;
                }
            }

        }

        .AnalyticChart{
            visibility: visible;
            padding-top: 50px;
            .chart{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 800px;
                height: 400px;
            }
            .list{
                display: flex;
                flex-direction: column;
                justify-content: right;
                align-items: right;
                padding: 10px;
        
                .visitor{
                    display: flex;
                    flex-direction: column;
                    padding: 5px;
                    padding: 10px;
                }
        
            }
            .filterOptions{
                display: flex;
                justify-content: center;
                align-items: center;
                .filterOption{
                    padding: 10px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    cursor: pointer;
                    color: black;
                    font-size: small;

                    margin: 5px;
                    margin-top: -2px;
    
                    border: 1px solid gray;
                    background-color: whitesmoke;
                    border-radius: 12px;
                }
            }
            .filterDiv{
                display: flex;
                justify-content: center;
                align-items: center;

                input{
                    width: 200px;
                    height: 30px;
                    margin: 5px;
                    padding: 5px;
                    border-radius: 20px;
                    border: 1px solid gray;
                }
                .icon{
                    background-color: whitesmoke;
                    padding: 8px;
                    border: 1px solid gray;
                    
                    border-radius: 50%;

                    cursor: pointer;

                    &:hover{
                        color: #3498DB;
                        background-color: whitesmoke;
                    }
                }
            }
        }

        .AnalyticChart.false{
            visibility: hidden;
            height: 0px;
        }


        .StudiosList{
            visibility: visible;
            height: fit-content;
        }
        .StudiosList.false{
            visibility: hidden;
            height: 0px;
        }

        .gallery.true{
            visibility: visible;
            height: fit-content;
        }
        .gallery.false{
            visibility: hidden;
            height: 0px;
        }
    }
}