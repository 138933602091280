.credentialsWindow{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    padding-top: 70px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    border-radius: 30px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(70, 70, 70, 0.47);
    gap: 5px;
    z-index: 999;

    form{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        input{
            width: 180px;
            height: 30px;
            margin: 10px;
            padding: 0 1em 0 1em;
            border-radius: 5px;
        }
    
        button{
            margin: 10px;
            width: 180px;
            padding: 0 1em 0 1em;
            height: 40px;
            border: none;
            background-color: #3498DB;
            color: white;
            font-weight: bold;
            border-radius: 5px;
            cursor: pointer;
        }
    
        span{
            font-size: 12px;
            color: red;
            margin-top: 10px;
        }
        .askPassword{
            display: flex;
            cursor: pointer;
            
            display: flex;
            justify-content: center;
            align-items: center;
    
            .ask{
                height: 20px;
                width: 20px;
                margin: 5px;
                color: black;
                padding: 10px;
                scale: 1;
                opacity: 1;
                transition: all 0.25s ease;
                border-radius: 50%;
                background-color: rgba($color: #3498DB, $alpha: 0);

                display: flex;
                align-items: center;
                justify-content: center;

                .logo{
                    width: 20px;
                    height: auto;
                }
                .icon{
                    width: 30px;
                    height: auto;
                }
            }
    
            .ask:hover{
                opacity: 0.8;
                transition: all 0.5s ease;
                scale: 1.05;
                background-color: rgba($color: #3498DB, $alpha: 0.3);
    
            }
        }
    }

}