.navbar{
    position: fixed;
    z-index: 900;
    width: 100%;
    box-shadow: 0px -10px 20px 20px rgba($color: #000000, $alpha: 0.15);

    .top{
        display: flex;

        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        padding-right: 60px;
        padding-left: 60px;


        .mainLogo{
            padding: 10px;
            width: 180px;
            height: auto;
            padding-right: 30px;
        }

        .right{
            display: flex;
            align-items: center;
            justify-content: right;
            width: 180px;
            

            .login{
                position: relative;
                visibility: hidden;
                margin: 10px;
                width: fit-content;
                height: fit-content;
                color: gray;

                h1{
                    padding: 5px;
                    font-size: 15px;
                }

                .loginButton{
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 40px;
                    z-index: 990;

                    padding: 10px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    
                    cursor: pointer;
                    background-color: whitesmoke;
                    border: 1px solid rgba($color: #000000, $alpha: 0.2);


                    .icon{
                        font-size: 18px;
                    }
        
                    &:hover{
                        color: black;
                        background-color: white;
                        border: 1px solid rgba($color: #000000, $alpha: 0.2);
                    }
                }

                .contextMenu{
                    visibility: hidden;
                    position: absolute;
                    background-color: whitesmoke;
                    width: calc(100% - 2px);
                    height: fit-content;
                    left: 0;
                    border: 1px solid rgba($color: #000000, $alpha: 0.2);
                    border-top: 0px;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    padding-bottom: 5px;
                    padding-top: 20px;
                    padding-bottom: 10px;

                    opacity: 0;
                    transition: all 0.25s ease;
                    top: 0px;
                    z-index: 500;

                    .option{
                        transition: all 0.5s ease;
                        padding-left: 12px;
                        transform: translate(0px, -20px);
                        cursor: pointer;
                        &:hover{
                            color: black;
                            background-color: white;
                        }

                    }
                }
                .contextMenu.true {
                    visibility: visible;
                    opacity: 1;
                    top: 25px;
                    transition: all 0.5s ease;
                    .option{
                        transform: translate(0px, 0px);
                        transition: all 0.5s ease;
                    }
                }
            }

            .login.true {
                visibility: visible;
            }
            
    
            .social{
                width: fit-content;
                height: auto;
    
                display: flex;
                flex-direction: row;
    
                text-align: center;
                align-items: center;
                justify-content: right;
    
                .socialLogo{
                    width: 20px;
                    height: 20px;
                    padding: 5px;
                }
            }

            .menuToggle{
                display: none;
    
                text-align: center;
                align-items: center;
                justify-content: center;
                cursor: pointer;
    
    
                .menuToggle{
                    display: flex;
                }
                .menuToggle.open{
                    display: none;
                }
    
                .menuClose{
                    display: none;
                }
                .menuClose.open{
                    display: flex;
                }
    
                .icon{
                    font-size: 25px;
                    color: black;
                }
                &:hover{
                    .icon{
                        color: rgb(59, 59, 59);
                    }
                }
            }
        }



        .sections{
            display: flex;
            flex-direction: row;

            text-align: center;
            align-items: center;
            justify-content: center;
            

            .section{
                position: relative;
                margin: 10px;
                width: fit-content;
                height: fit-content;
                cursor: pointer;
            }
        }
    }

    .optionMenu{
        background-color: whitesmoke;

        .menu{
            padding-left: 100px;
            padding-top: 0px;
            padding-bottom: 0px;
            height: fit-content;
            max-height: 0px;
            transition: all 0.25s ease;
            overflow: hidden;
            justify-items: space-between;
            
            .sections{
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .section{
                    width: fit-content;
                    letter-spacing: .2rem;
                    padding: 15px;
                    font-size: 15px;
                    opacity: 0;
                    padding: 5px;
                    transition: all 0.25s ease;
                    height: fit-content;

                    .sectionButtonArea{
                        text-align: left;
                    }

                    .sectionContextMenu{
                        position: inherit;
                        width: fit-content;
                        transform: translate(50px);
                        background-color: rgba($color: #000000, $alpha: 0);
                        box-shadow: 0px 0px 0px 0px;
                        z-index: 990;

                        .projectButton{
                            opacity: 1;
                            background-color: none;
                            width: fit-content;
                            &:hover{
                                border-radius: 60px;
                            }
                        }
                    }
                    .sectionContextMenu.open{
                        max-width: 100%;
                    }
                }
            }

            .social{                
                margin-left: auto;
                margin-right: 100px;
                justify-content: center;
                align-items: center;

                display: flex;
                flex-direction: row;

                opacity: 0;
                padding: 10px;


                transform: translateY(120px);
                transition: all 0.25s ease;
    
                .socialLogo{
                    width: 15px;
                    height: 15px;
                    padding: 10px;
                    transition: all 0.25s ease;
                }
            }
        
        }
        
        .menu.open{
            padding-left: 100px;
            padding-bottom: 30px;
            max-height: 800px;

            transition: all 0.5s ease;

            .sections{
                .section{
                    opacity: 1;
                    transition: all 0.5s ease;
                }
            }

            .social{
                opacity: 1;
                transform: translateY(0px);
                transition: all 0.5s ease;
                .socialLogo{
                    width: 20px;
                    height: 20px;
                    padding: 10px;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    @media (max-width: 1200px){
        .top{
            .sections{
                display: none;
            }
            .right{
                .social{
                    display: none;
                }
                .menuToggle{
                    display: flex;
                }
            }
        }
    }
    @media (min-width: 1200px){
        .optionMenu{
            .menu.open{
                padding-left: 100px;
                padding-top: 0px;
                padding-bottom: 0px;
                height: 0px;
                transition: all 0.25s ease;

                .social{
                    transform: translateY(120px);
                    transition: all 0.25s ease;
        
                    .socialLogo{
                        width: 20px;
                        height: 20px;
                        padding: 10px;
                        transition: all 0.25s ease;
                    }
                }
            }
        }
    }
}
