* {
    margin: 0;
    padding: 0;
    box-sizing: content-box;
  }
ul {
    list-style: none;
}
.ProjectListContainer{
    justify-content: center;
    align-items: center;

    .credentialsContainer{
        display: flex;
        visibility: hidden;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 998;
        height: 100vh;
        flex-direction: column;
    
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        transition: all 0.25s ease;
        opacity: 0;
        

        .credentialBG{
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .6);

            transition: all 0.25s ease;
        }

        .credentials{
            transition: all 0.5s ease;
            scale: 0.5;
        }
    }
    .credentialsContainer.ask{
        visibility: visible;

        transition: all 0.5s ease;
        opacity: 1;

        .credentials{
            transition: all 0.5s ease;
            scale: 1;
        }

        .credentialBG{
            transition: all 0.5s ease;
        }
    }

    .gallery{
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
    }
        
    
    .gallery > li {
        flex: 1 1 auto;
        height: 35vw;
        max-height: 240px;
        cursor: pointer;
        position: relative;
        border-radius: 8px;
        
        overflow: hidden;
    }

    .gallery::after {
        content: "";
        flex-grow: 0;
      }

        
    .gallery li img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        transition: all 0.4s ease;

    }
    .imgFrame{
        position: relative;
        justify-content: center;

        .locked{
            .unLocked{
                position: absolute;
                top: 0%;
                left: 0%;
                width: 100%;
                height: 100%;
                color: white;
    
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                opacity: 1;

                .icon{
                    transition: all 0.25s ease;
                    font-size: 150%;
                    padding: 10px;
                    background-color: rgba($color: rgb(0, 0, 0), $alpha: 0.3);
                    border-radius: 50%;
                    color: white;
                }
            }
            .unLocked.true{
                opacity: 0;
            }


        }
        .locked.hidden{
            opacity: 0;
        }

        .descriptionContent{
            .description{
                position: absolute;
                top: 0%;
                left: 0%;
                width: 100%;
                height: 100%;
    
                background-color: rgba($color: rgb(0, 0, 0), $alpha: 0.3);
                color: white;
                font-size: x-large;
                scale: (1);
                transition: all 0.25s ease;
                opacity: 0;
    
                display: flex;
                flex-direction: column;
    
                align-items: center;
                justify-content: center;
    
                cursor: pointer;
            }
        }
        .descriptionContent.locked{
            opacity: 1;
        }
        .descriptionContent.unlocked{
            opacity: 0;
        }
    }
    .imgFrame:hover{
        img{
            transition: all 1s ease;
            opacity: 0.7;
            transform: scale(1.02);
        }
        .description{
            top: 0;
            transition: all 0.5s ease;
            opacity: 1;
            scale: (1.1);
        }
        .locked{
            .icon{
                transition: all 0.5s ease;
                transform: translate(0, -50px);
            }
        }
    } 


    .gallery li img.hidden{
        filter: brightness(1.2) + saturate(1) + blur(20px);
        scale: 1.3;
    }
}