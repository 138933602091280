.sectionButtonArea{
    position: relative;
    letter-spacing: .2rem;
    text-align: center;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0px;
    font-size: 15px;
    z-index: 900;
    color: gray;
    border-radius: 10px;
    cursor: pointer;

    .sectionButton{
        display: flex;
        flex-direction: column;
        width: fit-content;
        .sectionHr{
            width: 100%;
            border: 1px solid gray;
            visibility: hidden;
            top: 2px;
        }
        .sectionHr.open{
            visibility: visible;
        }
    }

    &:hover{
        color: black;
    }

}
.sectionContextMenu{
    display: flex;
    flex-direction: column;
    position: absolute;
    
    padding-top: 10px;
    left: -25%;
    border: 0px solid rgba($color: #000000, $alpha: 0.2);
    border-top: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 5px;
    text-align: left;
    background-color: whitesmoke;

    opacity: 0;
    visibility: hidden;
    width: calc(150% - 0px);
    height: fit-content;
    max-height: 0px;
    transition: all 0.25s ease;
    top: 0px;
    z-index: 500;

    box-shadow: 4px 20px 20px -5px rgba($color: #000000, $alpha: 0.15);

    .projectButton{
        display: flex;
        flex-direction: column;
        width: 100%;

        .projectButtonContent{
            width: fit-content;

            .projectHR{
                border: 1px solid gray;
                top: 2px;
                margin-top: -5px;
                margin-bottom: 5px;
                margin-left: 20px;
                visibility: hidden;
            }
            .projectHR.true{
                
                visibility: visible;
            }
    
        
            .project{
                padding: 10px;
                padding-left: 20px;
                height: fit-content;
                font-size: normal;
                letter-spacing: 0.05rem;
                color: gray;
                cursor: pointer;
        
                display: flex;
                flex-direction: row;
                align-items: center;
        
                .projectLocked{
                    display: flex;
                    flex-direction: row;
                    .unLocked{
                        color: white;
                        opacity: 1;
        
                        .icon{
                            transition: all 0.25s ease;
                            font-size: 120%;
                            padding-left: 5px;
                            padding-right: 5px;
                            color: gray;
                        }
                    }
                    .unLocked.true{
                        visibility: hidden;
                        display: none;
                    }
                    
                }
                .projectLocked.hidden{
                    visibility: hidden;
                    display: none;
                }
        
                .projectName{
                    cursor: pointer;
                }
            }

        }
        &:hover{
            background-color: white;
            color: black;
            
            .locked{
                .icon{
                    color: black;
                }
            }
        }
        
    }
}
.sectionContextMenu.open{
    visibility: visible;
    display: flex;
    opacity: 1;

    top: 30px;

    max-height: 1000px;
    transition: all 0.5s ease;
}