.page{
    background-color: white;
    height: 100vh;
    
    display: flex;
    flex-direction: column;
    
    .top{
        background-color: whitesmoke;
        .sections, .logo{
            color: black;
        }
    }

    .mainContainer{
        margin-top: 160px;

        background-color: white;
        color: black;

        display: flex;
        flex-direction: column;
        flex-grow: 1;
        
        text-align: center;
        align-items: space-between;
        justify-content: center;

        .content{
            padding: 100px;
            padding-top: 0px;
            padding-bottom: 50px;
        }
    }
}