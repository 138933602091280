* {
    margin: 0;
    padding: 0;
    box-sizing: content-box;
  }
ul {
    list-style: none;
}
.galleryContainer{
    justify-content: center;
    align-items: center;

    .galleryTitle{
        padding: 20px;


        .logoProject{
            object-fit: contain;
            width: 100%;
            max-height: 200px;
        }
        .logoProject2{
            object-fit: contain;
            width: 50%;
            max-height: 120px;
        }
    }
    .gallery{
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
        
    }
        
    
    .gallery > li {
        flex: 1 1 auto;
        height: 25vh;
        max-height: 280px;
        cursor: pointer;
        position: relative;
        border-radius: 8px;
        
        overflow: hidden;
    }

    .gallery::after {
        content: "";
        flex-grow: 5;
      }

        
    .gallery li img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        transition: all 0.4s ease;

        &:hover{
            transition: all 1s ease;
            filter: opacity(.90);
            transform: scale(1.02);
        }
    }
    .description{
        position: absolute;
        padding: 10px;
        left: 0;
        bottom: 10px;
        color: white;
        transition: all 0.4s ease;
        filter: opacity(0);
        cursor: pointer;
    }
    
    .imgArea:hover{
        transition: all 0.8s ease;
        filter: opacity(.95);
        transform: scale(1.05);

        .description{
            color: white;
            transition: all 0.4s ease;
            filter: opacity(1);
        }
    }

    
    
}
/*model*/

.model{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    transition: opacity .4s ease, opacity .4s ease;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 998;

    .bg{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.98);
        transition: opacity .4s ease, opacity .4s ease;
    }
    
    .description{
        display: block;
        max-width: 100%;
        max-height: 100vh;
        width: auto;
        padding: 100px;
        padding-top: 0px;

        position: relative;
        color: white;
        z-index: 998;
    }
}

.model.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.mainImg{
    transition: opacity .4s ease, opacity .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.5);
    overflow: hidden;
    z-index: 400;
}

.mainImg.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img{

    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100vh;
    width: auto;

    line-height: 0;
    box-sizing: border-box;
    padding: 100px;
    padding-bottom: 10px;
}

.model.open .close{
    transition: all 0.25s ease;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgb(50, 50, 50);
    border-radius: 50%;
    color: white;
    cursor: pointer;
    z-index: 999;
}

.model.open .back{
    transition: all 0.25s ease;
    position: fixed;
    left: 15px;
    width: 2rem;
    height: 2rem;
    padding: 10px;
    background-color: rgb(50, 50, 50);
    border-radius: 50%;
    color: white;
    cursor: pointer;
    z-index: 999;
}

.model.open .forward{
    transition: all 0.25s ease;
    position: fixed;
    right: 15px;
    width: 2rem;
    height: 2rem;
    padding: 10px;
    background-color: rgb(50, 50, 50);
    border-radius: 50%;
    color: white;
    cursor: pointer;
    z-index: 999;
}
.model.open .close:hover{
    transition: all 0.5s ease;
    filter: opacity(.95);
    transform: scale(0.95);
}
.model.open .back:hover{
    transition: all 0.5s ease;
    filter: opacity(.95);
    transform: scale(0.95);
}
.model.open .forward:hover{
    transition: all 0.5s ease;
    filter: opacity(.95);
    transform: scale(0.95);
}