.contentProfile{
    display: flex;
    padding-top: 50px;
    justify-content: center;
    align-items: top;

    .profilePic{
        flex: 1;
        min-width: 250px;
        max-width: 350px;
        height: 450px;
        max-height: 500px;

        overflow: hidden;
        border-radius: 10px;


        display: flex;
        justify-content: center;

        .profileImg{
            margin: auto;
            display: block;
            width: 700px;
            scale: 1.1;
            transform: translate(45px, -250px);
        }
    }
    .profileDesc{
        h1{
            font-size: 50px;
            font-style: oblique;
            font-size: 40px;
        }
        flex: 3;
        min-width: 320px;
        max-width: 500px;
        padding-left: 20px;
        text-align: left;
        color: black;
        font-size: normal;


        .contactButtons{
            display: flex;
            cursor: pointer;
            
            display: flex;
            justify-content: left;
            align-items: center;

            margin-left: 50px;
    
            .ask{
                margin: 5px;
                color: black;
                padding: 10px;
                scale: 1;
                opacity: 1;
                transition: all 0.25s ease;
                border-radius: 50%;
                background-color: rgba($color: #3498DB, $alpha: 0);
                .logo{
                    width: 18px;
                    height: 18px;
                }
            }
    
            .ask:hover{
                opacity: 0.8;
                transition: all 0.5s ease;
                scale: 1.05;
                background-color: rgba($color: #3498DB, $alpha: 0.3);
    
            }
        }


    }
}