.credentialsContainer{
    display: flex;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    height: 100vh;
    flex-direction: column;

    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: all 0.25s ease;
    opacity: 0;
    

    .credentialBG{
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);

        transition: all 0.25s ease;
    }

    .credentials{
        transition: all 0.5s ease;
        scale: 0.5;
    }
}
.credentialsContainer.ask{
    visibility: visible;

    transition: all 0.5s ease;
    opacity: 1;

    .credentials{
        transition: all 0.5s ease;
        scale: 1;
    }

    .credentialBG{
        transition: all 0.5s ease;
    }
}